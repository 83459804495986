<template>
    <div>
        <v-card class="rounded-lg" elevation="0" max-height="190px">
            <div class="thumbnail-container">
                <v-img
                    class="rounded-lg"
                    width="100%"
                    height="100%"
                    :src="thumbnail"
                >
                </v-img>
            </div>
        </v-card>

        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title
                    class="font-weight-bold success--text c-pointer mb-2"
                    @click="$emit('onViewInformation')"
                >
                    {{ title }}
                </v-list-item-title>
                <v-list-item-subtitle
                    class="secondary--text text-uppercase mb-2"
                    >{{ learnCollection.name }}</v-list-item-subtitle
                >
                <v-list-item-subtitle v-if="firstSubject">
                    <v-chip
                        class="font-weight-bold secondary mr-2 text-capitalize"
                        small
                        dark
                    >
                        {{ firstSubject }}
                    </v-chip>
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openAlertDialog"
                        >
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </template>
                    <span>Delete Material</span>
                </v-tooltip>
            </v-list-item-action>
        </v-list-item>

        <base-alert-dialog
            :is-open.sync="alertDialog.open"
            :title="alertDialog.title"
            :description="alertDialog.description"
            :theme="alertDialog.theme"
            :loading="isDeleteStart"
            @onCancel="alertDialog.open = false"
            @onProceed="deleteVideo"
        ></base-alert-dialog>
    </div>
</template>

<script>
import BaseAlertDialog from "@/components/Base/BaseAlertDialog";
import { LEARN_DELETE_MATERIAL } from "@/store/modules/learn";

export default {
    name: "learn-material-preview-card",

    components: { BaseAlertDialog },

    props: {
        materialID: Number,
        title: String,
        thumbnail: String,
        learnCollection: Object,
        subjects: [Array, null, undefined],
    },

    data() {
        return {
            isDeleteStart: false,
            alertDialog: {
                open: false,
                theme: "warning",
                title: null,
                description: null,
            },
        };
    },

    computed: {
        firstSubject() {
            let subject = null;
            if (!this.subjects || this.learnCollection.id === 2) return subject;
            if (this.subjects.length === 0) return subject;
            const { title } = this.subjects[0];
            const _title = title;
            subject = `Life Journey 1: ${_title}`;

            return subject;
        },
    },

    methods: {
        async deleteVideo() {
            this.isDeleteStart = true;
            const { success } = await this.$store.dispatch(
                LEARN_DELETE_MATERIAL,
                this.materialID
            );
            if (success) {
                this.alertDialog.open = false;
                this.isDeleteStart = false;
                this.$emit("onDeleteSuccess");
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    duration: 5000,
                    title: "Watch",
                    text: "Material was deleted.",
                });
                return;
            }
            this.isDeleteStart = false;
        },

        openAlertDialog() {
            this.alertDialog = Object.assign(
                {},
                {
                    ...this.alertDialog,
                    title: "Delete Material",
                    description: `Are you sure you want to delete ${this.title}? Click proceed if you are sure.`,
                    open: true,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.thumbnail-container {
    position: relative;
    width: 100%;
    height: 190px;
}

.thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
}
</style>
