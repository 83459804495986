var render = function render(){var _vm=this,_c=_vm._self._c;return _c('base-dialog',{attrs:{"width":"1069","is-open":_vm.dialog},on:{"update:isOpen":function($event){_vm.dialog=$event},"update:is-open":function($event){_vm.dialog=$event}}},[_c('v-card-title',{staticClass:"align-center justify-start",staticStyle:{"background-color":"#f1eeee"}},[_c('v-btn',{staticClass:"border-radius-25 px-5 text-capitalize",attrs:{"rounded":"","dark":"","color":"success","depressed":""},on:{"click":_vm.openUpdateVideoForm}},[_vm._v("Update")])],1),(!!_vm.error)?_c('v-alert',{attrs:{"tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"px-5"},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.thumbnail)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-img',{staticClass:"rounded-lg",attrs:{"width":"100%","max-height":"548px","src":_vm.thumbnail}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center align-content-center"},[_c('v-list-item',{staticStyle:{"width":"100%"},attrs:{"three-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({
                                                        on,
                                                        attrs,
                                                    }){return [_c('span',_vm._g(_vm._b({staticClass:"font-weight-bold success--text",staticStyle:{"word-wrap":"break-word"}},'span',attrs,false),on),[_vm._v(_vm._s(_vm.title))])]}}])},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1),(_vm.learnCollection)?_c('v-list-item-subtitle',{staticClass:"secondary--text text-uppercase mb-2"},[_vm._v(_vm._s(_vm.learnCollection.name))]):_vm._e(),(_vm.firstSubject)?_c('v-list-item-subtitle',[_c('v-chip',{staticClass:"font-weight-bold secondary mr-2 text-capitalize",attrs:{"small":"","dark":""}},[_vm._v(" "+_vm._s(_vm.firstSubject)+" ")])],1):_vm._e()],1)],1)],1)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{class:[
                                    { 'left-border': !!_vm.description },
                                    'd-flex fill-height align-center px-5  black--text',
                                ]},[_vm._v(" "+_vm._s(_vm.description)+" ")])])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }