<template>
    <base-dialog width="722" :title="dynamicTitles.card" :is-open.sync="dialog">
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-form v-model="formData.status" ref="form" lazy-validation>
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" md="6">
                            <base-input-label required
                                >Select Collection</base-input-label
                            >
                            <v-select
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                append-icon="mdi-chevron-down"
                                hide-details
                                color="secondary"
                                :items="learnCollections"
                                item-value="id"
                                item-text="name"
                                v-model="form.learn_collection_id"
                            ></v-select>
                        </v-col>

                        <v-col cols="12" md="6">
                            <base-input-label optional>Tags</base-input-label>
                            <v-combobox
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                hide-details
                                multiple
                                small-chips
                                append-icon=""
                                item-text="name"
                                v-model="form.tags"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip
                                        class="white--text"
                                        color="secondary"
                                        small
                                        :key="JSON.stringify(item)"
                                        >{{ item.name || item }}</v-chip
                                    >
                                </template>
                            </v-combobox>
                        </v-col>

                        <v-col cols="12">
                            <base-input-label required hint="max. 5mb">{{
                                dynamicTitles.thumbnail
                            }}</base-input-label>

                            <div
                                class="d-flex justify-space-between align-center"
                            >
                                <v-file-input
                                    solo
                                    rounded
                                    :prepend-icon="null"
                                    show-size
                                    small-chips
                                    color="secondary"
                                    clearable
                                    flat
                                    hide-details
                                    accept="image/*"
                                    class="rounded-lg"
                                    id="thumbnail"
                                    v-model="form.thumbnail"
                                >
                                    <template v-slot:selection="{ text }">
                                        <v-chip color="secondary" small label>
                                            {{ text }}
                                        </v-chip>
                                    </template>
                                </v-file-input>
                                <div class="mx-2"></div>
                                <v-btn
                                    class="caption font-weight-bold text-capitalize"
                                    small
                                    depressed
                                    rounded
                                    dark
                                    color="secondary"
                                    @click="openFileInput('thumbnail')"
                                    >Select File</v-btn
                                >
                            </div>

                            <v-menu
                                max-width="20rem"
                                top
                                v-if="
                                    operation.editMode &&
                                    form.existing_thumbnail
                                "
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <div class="pt-2">
                                        <span
                                            class="caption text-decoration-underline secondary--text c-pointer"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            View existing image
                                        </span>
                                    </div>
                                </template>
                                <v-card width="20rem">
                                    <v-img
                                        :src="form.existing_thumbnail"
                                        width="100%"
                                        height="auto"
                                    ></v-img>
                                </v-card>
                            </v-menu>
                        </v-col>

                        <v-col cols="12">
                            <base-input-label required>Title</base-input-label>
                            <v-text-field
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                hide-details
                                v-model="form.title"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <base-input-label optional
                                >Subtitle</base-input-label
                            >
                            <v-text-field
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                hide-details
                                v-model="form.sub_title"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="12">
                            <base-input-label required
                                >Description</base-input-label
                            >
                            <v-textarea
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                hide-details
                                v-model="form.description"
                            ></v-textarea>
                        </v-col>

                        <v-col cols="12" v-if="isSermonStudyGuide">
                            <base-input-label required>PDF URL</base-input-label>
                            <v-text-field
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                hide-details
                                v-model="form.pdf_url"
                            ></v-text-field>

                            <div class="pt-2" v-if="form.existing_pdf_url">
                                <a
                                    :href="form.existing_pdf_url"
                                    target="_blank"
                                >
                                    <span
                                        class="caption text-decoration-underline secondary--text c-pointer"
                                    >
                                        View existing file
                                    </span>
                                </a>
                            </div>
                        </v-col>

                        <v-col cols="12" v-if="isSermonStudyGuide">
                            <base-input-label>Videos</base-input-label>
                            <v-select
                                solo
                                rounded
                                class="rounded-lg"
                                flat
                                append-icon="mdi-chevron-down"
                                hide-details
                                small-chips
                                color="secondary"
                                :items="videos"
                                item-value="id"
                                item-text="title"
                                multiple
                                v-model="form.video_relates"
                            >
                                <template v-slot:selection="{ item }">
                                    <v-chip color="secondary" small label>
                                        {{ item.title }}
                                    </v-chip>
                                </template>
                            </v-select>
                        </v-col>

                        <v-col cols="12" v-if="shouldShowTract">
                            <base-input-label>
                                <span class="mr-2">Tract</span>
                                <v-btn
                                    color="primaryTextColor"
                                    fab
                                    x-small
                                    depressed
                                    @click="addLifeJourneySubject"
                                    ><v-icon color="white"
                                        >mdi-plus</v-icon
                                    ></v-btn
                                >
                            </base-input-label>

                            <template
                                v-for="(
                                    subject, subjectIndex
                                ) in form.life_journey_subjects"
                            >
                                <div
                                    :class="`tract-container ${
                                        hasLifeJourneySubjects ? 'mb-5' : ''
                                    }`"
                                    :key="subjectIndex"
                                >
                                    <v-row>
                                        <v-col cols="12" class="mb-0 pb-0">
                                            <div
                                                class="d-flex justify-space-between align-center"
                                            >
                                                <span
                                                    class="caption secondary--text text-uppercase"
                                                    >#{{
                                                        subjectIndex + 1
                                                    }}</span
                                                >
                                                <v-btn
                                                    icon
                                                    @click="
                                                        removeLifeJourneySubject(
                                                            subjectIndex
                                                        )
                                                    "
                                                    v-if="subjectIndex !== 0"
                                                    ><v-icon
                                                        >mdi-trash-can</v-icon
                                                    ></v-btn
                                                >
                                            </div>
                                        </v-col>

                                        <v-col cols="12">
                                            <base-input-label required
                                                >Title</base-input-label
                                            >
                                            <v-text-field
                                                solo
                                                rounded
                                                class="rounded-lg"
                                                flat
                                                hide-details
                                                v-model="subject.title"
                                            ></v-text-field>
                                        </v-col>

                                        <v-col cols="12">
                                            <base-input-label required
                                                >Description</base-input-label
                                            >
                                            <v-textarea
                                                solo
                                                rounded
                                                class="rounded-lg"
                                                flat
                                                hide-details
                                                v-model="subject.description"
                                            ></v-textarea>
                                        </v-col>

                                        <v-col cols="12 ">
                                            <base-input-label>
                                                <span class="mr-2"
                                                    >Add PDF</span
                                                >
                                                <v-btn
                                                    color="primaryTextColor"
                                                    fab
                                                    x-small
                                                    depressed
                                                    @click="
                                                        addLifeJourneySubjectFile(
                                                            subjectIndex
                                                        )
                                                    "
                                                    ><v-icon color="white"
                                                        >mdi-plus</v-icon
                                                    ></v-btn
                                                >
                                            </base-input-label>

                                            <template
                                                v-for="(
                                                    file, fileIndex
                                                ) in subject.files"
                                            >
                                                <div
                                                    :class="`tract-container ${
                                                        subject.files.length > 0
                                                            ? 'mb-3'
                                                            : ''
                                                    }`"
                                                    :key="fileIndex"
                                                >
                                                    <v-row>
                                                        <v-col
                                                            cols="12"
                                                            class="mb-0 pb-0"
                                                        >
                                                            <div
                                                                class="d-flex justify-space-between align-center"
                                                            >
                                                                <span
                                                                    class="caption secondary--text text-uppercase"
                                                                    >#{{
                                                                        fileIndex +
                                                                        1
                                                                    }}</span
                                                                >
                                                                <v-btn
                                                                    icon
                                                                    @click="
                                                                        removeLifeJourneySubjectFile(
                                                                            subjectIndex,
                                                                            fileIndex
                                                                        )
                                                                    "
                                                                    v-if="
                                                                        fileIndex !==
                                                                        0
                                                                    "
                                                                    ><v-icon
                                                                        >mdi-trash-can</v-icon
                                                                    ></v-btn
                                                                >
                                                            </div>
                                                        </v-col>

                                                        <v-col cols="12">
                                                            <base-input-label
                                                                required
                                                                hint="max. 5mb"
                                                                >{{
                                                                    dynamicTitles.file
                                                                }}</base-input-label
                                                            >

                                                            <div
                                                                class="d-flex justify-space-between align-center"
                                                            >
                                                                <v-text-field
                                                                    solo
                                                                    rounded
                                                                    class="rounded-lg"
                                                                    flat
                                                                    hide-details
                                                                    :style="{
                                                                        display:
                                                                            file.file
                                                                                ? 'none '
                                                                                : 'block',
                                                                    }"
                                                                    v-model="
                                                                        file.pdf_url
                                                                    "
                                                                ></v-text-field>

                                                                <v-file-input
                                                                    solo
                                                                    rounded
                                                                    :prepend-icon="
                                                                        null
                                                                    "
                                                                    show-size
                                                                    small-chips
                                                                    color="secondary"
                                                                    flat
                                                                    hide-details
                                                                    accept="application/pdf"
                                                                    class="rounded-lg"
                                                                    :id="
                                                                        toInputFileElementID(
                                                                            subjectIndex,
                                                                            fileIndex
                                                                        )
                                                                    "
                                                                    :style="{
                                                                        display:
                                                                            file.file
                                                                                ? 'block '
                                                                                : 'none',
                                                                    }"
                                                                    clearable
                                                                    @click:clear="
                                                                        file.file =
                                                                            null
                                                                    "
                                                                    v-model="
                                                                        file.file
                                                                    "
                                                                >
                                                                    <template
                                                                        v-slot:selection="{
                                                                            text,
                                                                        }"
                                                                    >
                                                                        <v-chip
                                                                            small
                                                                            label
                                                                            color="secondary"
                                                                        >
                                                                            {{
                                                                                text
                                                                            }}
                                                                        </v-chip>
                                                                    </template>
                                                                </v-file-input>

                                                                <div
                                                                    class="mx-2 caption font-weight-bold secondary--text"
                                                                >
                                                                    or
                                                                </div>

                                                                <v-btn
                                                                    class="caption font-weight-bold text-capitalize"
                                                                    small
                                                                    depressed
                                                                    rounded
                                                                    dark
                                                                    color="secondary"
                                                                    @click="
                                                                        openFileInput(
                                                                            toInputFileElementID(
                                                                                subjectIndex,
                                                                                fileIndex
                                                                            )
                                                                        )
                                                                    "
                                                                    >Select
                                                                    File</v-btn
                                                                >
                                                            </div>

                                                            <div
                                                                class="pt-2"
                                                                v-if="
                                                                    operation.editMode &&
                                                                    (file.pdf_url ||
                                                                        file.existing_file)
                                                                "
                                                            >
                                                                <a
                                                                    :href="
                                                                        file.pdf_url
                                                                            ? file.pdf_url
                                                                            : file.existing_file
                                                                    "
                                                                    target="_blank"
                                                                >
                                                                    <span
                                                                        class="caption text-decoration-underline secondary--text c-pointer"
                                                                    >
                                                                        View
                                                                        existing
                                                                        file
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </v-col>

                                                        <v-col cols="12">
                                                            <base-input-label
                                                                required
                                                                >PDF
                                                                Name</base-input-label
                                                            >
                                                            <v-text-field
                                                                solo
                                                                rounded
                                                                class="rounded-lg"
                                                                flat
                                                                hide-details
                                                                v-model="
                                                                    file.name
                                                                "
                                                            ></v-text-field>
                                                        </v-col>

                                                        <v-col cols="12" md="6">
                                                            <base-input-label
                                                                optional
                                                                >Total no. of
                                                                pages</base-input-label
                                                            >
                                                            <v-text-field
                                                                solo
                                                                rounded
                                                                class="rounded-lg"
                                                                flat
                                                                hide-details
                                                                type="number"
                                                                v-model="
                                                                    file.pages
                                                                "
                                                            ></v-text-field>
                                                        </v-col>

                                                        <v-col cols="12" md="6">
                                                            <base-input-label
                                                                optional
                                                                >Estimate no. of
                                                                hours</base-input-label
                                                            >
                                                            <v-text-field
                                                                solo
                                                                rounded
                                                                class="rounded-lg"
                                                                flat
                                                                hide-details
                                                                type="number"
                                                                v-model="
                                                                    file.hour_to_read
                                                                "
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </div>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </div>
                            </template>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import {
    LEARN_CREATE_MATERIAL,
    LEARN_UPDATE_MATERIAL,
} from "@/store/modules/learn";
import BaseDialog from "@/components/Base/BaseDialog";

const defaultForm = {
    learn_collection_id: null,
    title: null,
    sub_title: null,
    description: null,
    thumbnail: null,
    tags: [],
    life_journey_subjects: [
        {
            title: null,
            description: null,
            thumbnail: null,
            files: [
                {
                    name: null,
                    description: null,
                    pdf_url: null,
                    pages: 0,
                    hour_to_read: 0,
                    file: null,
                },
            ],
        },
    ],
    pdf_url: null,
    existing_pdf_url: null,
    video_relates: [],
};

export default {
    name: "learn-material-form-dialog",

    components: { BaseDialog, BaseInputLabel },

    props: {
        show: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            tab: "",
            dialog: this.show,
            collectionList: ["Sermon Study Guide", "Life Journey"],
            formData: Object.assign({}, defaultForm),
            form: Object.assign({}, defaultForm),
            inputTags: [],
            isActionStart: false,
            error: null,
            dialogContent: null,
        };
    },

    computed: {
        learnCollections() {
            return this.$store.state.selection.learnCollections;
        },

        videos() {
            return this.$store.state.selection.videos;
        },

        hasLifeJourneySubjects() {
            return this.form.life_journey_subjects.length > 0;
        },

        shouldShowTract() {
            const { learn_collection_id } = this.form;
            return learn_collection_id && learn_collection_id === 1;
        },

        isSermonStudyGuide() {
            const { learn_collection_id } = this.form;
            return learn_collection_id && learn_collection_id === 2;
        },

        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
                thumbnail: null,
                file: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add Material",
                        thumbnail: "Thumbnail",
                        file: "Paste pdf url or import pdf file",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit Material",
                        thumbnail: "Change Thumbnail",
                        file: "Change pdf url or import pdf file",
                    }
                );
            }

            return title;
        },
    },

    watch: {
        show(value) {
            this.dialog = value;
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                const {
                    id,
                    learn_collection,
                    thumbnail,
                    life_journey_subjects,
                    attachment,
                } = value;

                this.form = Object.assign(
                    {},
                    {
                        ...this.form,
                        ...value,
                        id,
                        learn_collection_id: learn_collection.id,
                        existing_thumbnail: thumbnail,
                        thumbnail: null,
                        pdf_url: null,
                        existing_pdf_url: null,
                        life_journey_subjects: this.formatSubjects(
                            life_journey_subjects,
                            learn_collection
                        ),
                    }
                );
                let pdfURLPreview = null;
                if (this.isSermonStudyGuide) {
                    pdfURLPreview = attachment.pdf_url;
                }
                this.form.existing_pdf_url = pdfURLPreview;
            }
        },

        dialog(value) {
            if (!value && this.operation.createMode) {
                this.$emit("close");
            } else if (!value && this.operation.editMode) {
                this.$emit("close");
                this.resetForm();
            }
        },
    },

    methods: {
        openFileInput(elementId) {
            this.$nextTick(() => {
                document.getElementById(elementId).click();
            });
        },

        async onCreate() {
            const payload = {
                ...this.form,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                LEARN_CREATE_MATERIAL,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Learn",
                    text: "Material was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            const payload = {
                ...this.form,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                LEARN_UPDATE_MATERIAL,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Learn",
                    text: "Material was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },

        addLifeJourneySubject() {
            this.form.life_journey_subjects = [
                ...this.form.life_journey_subjects,
                {
                    title: null,
                    description: null,
                    files: [],
                    thumbnail: null,
                },
            ];
        },

        removeLifeJourneySubject(subjectIndex) {
            this.form.life_journey_subjects =
                this.form.life_journey_subjects.filter(
                    (_, index) => index !== subjectIndex
                );
        },

        addLifeJourneySubjectFile(subjectIndex) {
            this.form.life_journey_subjects =
                this.form.life_journey_subjects.map((subject, index) => {
                    if (index === subjectIndex) {
                        subject.files = [
                            ...subject.files,
                            {
                                name: null,
                                description: null,
                                pdf_url: null,
                                pages: 0,
                                hour_to_read: 0,
                                file: null,
                            },
                        ];
                    }
                    return subject;
                });
        },

        removeLifeJourneySubjectFile(subjectIndex, fileIndex) {
            this.form.life_journey_subjects =
                this.form.life_journey_subjects.map((subject, index) => {
                    if (index === subjectIndex) {
                        subject.files = subject.files.filter(
                            (_, index2) => index2 !== fileIndex
                        );
                    }
                    return subject;
                });
        },

        toInputFileElementID(subjectIndex, fileIndex) {
            return `subject-${subjectIndex}-file-${fileIndex}`;
        },

        hasFile(file) {
            return !!file;
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        formatSubjects(subjects, learnCollection) {
            let _subjects = [];
            const isSermon = learnCollection.id === 2;
            if (isSermon) {
                return _subjects;
            }
            _subjects = subjects
                .map((subject) => Object.assign({}, subject)) // I cloned the subjects array first before changing its properties.
                .map((subject) => {
                    subject.files = subject.attachments.map((attachment) => {
                        return {
                            ...attachment,
                            existing_file: attachment.file,
                            file: null,
                        };
                    });
                    return subject;
                });

            return _subjects;
        },
    },
};
</script>

<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.tract-container {
    border: 1px dashed rgba(120, 117, 106, 0.5);
    padding: 1rem;
    border-radius: 5px;
}
</style>
